<template>
  <div class="container">
    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <h5 class="overflow-ellipsis">{{ (equipment.isDeviceLinked() ? `Module ${device.serial}` : 'No module linked') }}</h5>
        <span>{{ (equipment.isDeviceLinked() ? 'A MyLuxura module is linked to the sunbed' : 'Link a module to receive more information about your sunbed') }}</span>
      </div>
      <div v-if="$auth.hasPermission('equipment.connect_device') || $auth.hasPermission('equipment.disconnect_device')" class="col mt-3 mt-md-auto mb-md-auto text-right">
        <button
          v-if="$auth.hasPermission('equipment.connect_device')"
          type="button"
          :class="['btn w-150px', equipment.isDeviceLinked() ? 'btn-warning' : 'btn-success']"
          @click.prevent="openLinkModule"
        >
          {{ equipment.isDeviceLinked() ? 'Replace module' : 'Link module' }}
        </button>

         <button
          v-if="$auth.hasPermission('equipment.disconnect_device') && equipment.isDeviceLinked()"
          type="button"
          :class="['btn btn-danger ml-3 w-150px']"
          @click.prevent="unlinkModule"
        >
          Unlink module
        </button>
      </div>
    </div>

    <div class="d-none d-md-flex row border-top mt-3">
      <div class="col-12 text-center">
        <img :src="require('@/assets/images/module/myluxura-module.svg')" alt="MyLuxura Module" class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
import Equipment from '@/libs/classes/equipment';
import Device from '@/libs/classes/device';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
    device: {
      type: Device,
      default: null,
    },
  },

  watch: {
    $route (to) {
      if (to.name !== this.$route.name) {
        return;
      }

      this.onRoute();
    },
  },

  async mounted () {
    await this.$nextTick();

    // Open modal automatically
    this.onRoute();
  },

  methods: {
    onRoute () {
      if (typeof this.$route.query['link-module'] !== typeof undefined) {
        this.$router.replace({ ...this.$route, query: {} });
        this.openLinkModule();
      }
    },

    openLinkModule () {
      const vm = this;
      this.$eventhub.emit('modals:equipment:link-module:open', {
        onSubmit (response) {
          vm.$eventhub.emit(`document:${response._meta.index}:${response._meta.guid}:update`, response);
        },
      });
    },

    async unlinkModule () {
      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Unlink module',
        message: `Are you sure that you want to unlink module <strong>${vm.device.serial}</strong>?<br /><br />Please type the ID of the module to confirm:`,
        verifyString: vm.device.serial,
        buttonText: 'Unlink',
        async onSubmit () {
          const response = await vm.$ws.put(`/v2/equipment/${vm.equipment._meta.guid}/device/disconnect`);
          vm.$eventhub.emit(`document:${response._meta.index}:${response._meta.guid}:update`, response);
        },
      });
    },
  },
};
</script>
